.blogSection {
  max-width: 1300px;
  margin: 0 auto;
  padding: 65px 15px;
}
.blog_sub_heading::before {
  content: "";
  width: 35px;
  height: 35px;
  background: #fa5d32;
  opacity: 0.5;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -11;
}
.blog_sub_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  color: #161616;
  position: relative;
  z-index: 999;
  padding-left: 18px;
  margin-bottom: 10px;
}
.blog_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #161616;
  margin-bottom: 0px;
}
.arrowGroup {
  display: flex;
  align-items: end;
  column-gap: 20px;
}
.blogarrows:hover {
  background-color: #fa5d32;
  transition: 0.3s ease-in-out;
  color: #ffffff;
}
.blogarrows {
  border: 0;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  line-height: 40px;
  color: #fa5d3260;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fa5d3220;
}
.blog_caroseal{
  padding: 50px 0 0 0;
}
.mycard {
  position: relative;
  color: white;
}
.mycard:hover {
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.05);
  transition: 0.3s ease-in;
}
.product-carousel {
  position: relative;
  overflow: hidden;
}

.product-container {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.pre-btn,
.next-btn {
  border: none;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
}
.next-btn {
  right: 0;
}
.next-btn p,
.pre-btn p {
  font-size: 50px;
  background-color: rgba(255, 255, 255, 0.436);
  box-shadow: 0px 0px 10px 1px black;
  border-radius: 10px;
  color: rgb(0, 0, 0);
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.blog_img {
  width: 100%;
  z-index: -1;
  position: relative;
}
.blog_block {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 80%;
  padding: 20px 15px;
  background-color: white;
}
.blog_heading_section{
  display: flex;
  justify-content: space-between;
}
.blog_block_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
}
.blog_block_text {
  display: flex;
  justify-content: normal;
  margin-top: 22px;
}
.blog_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100px;
  color: #777777;
}
.blog_text_name {
  border-left: 2px solid rgb(240, 234, 234);
  padding-left: 25px;
}

.block_circle {
  position: absolute;
    bottom: 70px;
    right: -23px;
    width: 50px;
    height: 50px;
    background: #FA5D32;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.mycard:hover .block_circle{
  opacity: 1;
  transition: 1s ease-in;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .blog_img {
    width: 100%;
  }
  .blog_block {
    width: 70%;
    padding: 20px 15px;
  }
  .blog_block_text {
    margin-top: 10px;
  }
  .blog_block_heading {
    font-size: 16px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_heading {
    font-size: 30px;
    line-height: 40px;
  }
  .blog_sub_heading {
    font-size: 16px;
    line-height: 30px;
  }
  .blog_block {
    width: 70%;
    padding: 10px 15px;
    background-color: white;
  }
  .blog_block_heading {
    font-size: 15px;
    line-height: 22px;
  }
  .blog_block_text {
    margin-top: 12px;
}
.blog_text_name {
  padding-left: 14px;
}
.blog_text {
  font-size: 13px;
  line-height: 22px;
}
.blogSection {
  padding: 65px 0;
}
.blog_header_cont{
  padding: 0 20px;
}
.blog_caroseal {
  padding: 25px 0 0 0;
}

}
@media only screen and (max-width: 600px) {
  .blogSection {
    padding: 30px 0;
}
  .blog_heading {
    font-size: 21px;
    line-height: 35px;
}
  .blog_block_heading {
    font-size: 17px;
    line-height: 23px;
  }
  .blogarrows {
    display: none;
  }
  .blog_heading_section {
    display: block;
}
}
@media only screen and (max-width: 500px){
  .blog_block_heading {
    font-size: 12px;
    line-height: 15px;
}
.blog_text {
  font-size: 10px;
  line-height: 12px;
}
.blog_text_name {
  padding-left: 9px;
}
.blog_block {
  width: 80%;
  padding: 5px 5px;
  background-color: white;
}
.blog_heading {
  font-size: 18px;
  line-height: 23px;
}
.blog_sub_heading {
  font-size: 12px;
  line-height: 20px;
}
}
@media only screen and (max-width: 320px){
  .blog_sub_heading {
    font-size: 12px;
    line-height: 36px;
}
.blog_heading {
  font-size: 17px;
  line-height: 23px;
}
.blog_block {
  width: 80%;
  padding: 5px 5px;
  background-color: white;
}
.blog_block_heading {
  font-size: 10px;
  line-height: 12px;
}
.blog_text {
  font-size: 9px;
  line-height: 10px;
}
}