
.portfolio_banner{
    position: relative;
    z-index: 1;
}
.portfolio_banner_container{
    padding: 80px 80px;
    text-align: center;
}
.portfolio_banner:before{
    content: '';
    background-image: url(../../Images/portfolioBG.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height:500px;
    position: absolute;
    top: 0;
}
.portfolio_banner:after{
    content: '';
    background-color: black;
    height:500px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.portfolio_container{
    display: flex;
    justify-content: space-around;
    max-width: 1300px;
    margin: 0 auto;
    column-gap: 30px;
    position: relative;
    top: -80px;
    z-index: 1;
    padding: 20px 15px;
}
.banner_sub_heading::before{
  content: "";
    width: 35px;
    height: 35px;
    background: #FA5D32;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -11;
}
.banner_sub_heading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  z-index: 999;
  padding-left: 16px;
  width: fit-content;
  margin: 0 auto ;
}
.banner_heading{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #FFFFFF;
 
}
.portfolio_grid_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: 50px;
    grid-auto-flow: dense;
    grid-gap: 30px;
  }
  .large, .medium, .small {
    height: 100%;
    width: 100%;
    grid-column: auto / span 1;
  }
  .large {
    grid-row: span 6;
    
  }
  .medium {
    grid-row: span 5;
  }
  
  .small {
    grid-row: span 4;
  }
  .portfolio_grid_container  .cardImg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .portfoliocard{
    background-color: black;
    display: flex;
    margin: 0 auto;
    padding: 15px 15px;
    width: 75%;
    position: relative;
    top: -120px;
    z-index: 1;
    justify-content: space-between;
  }
  .portfoliocard:hover{
    background: #FA5D32;
    transition: 1s ease-out;
    cursor: pointer;
  }
  .sub_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: #FFFFFF;
  }
 .portfolio_card_angle_right{
  display: flex;
  align-items: center;
 }
  .cardicon{
    opacity: 0;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    background: white;
    justify-content: center;
    align-items: center;
    color: #FA5D32;
  }
  .portfoliocard:hover .cardicon{
    opacity: 1;
    transition: 1s ease-out;
}

@media only screen and (max-width: 1080px){
  .portfolio_banner_container{
    padding: 30px 15px 80px 15px;
  }
  .large {
    grid-row: span 7;
  }
  .medium {
    grid-row: span 7;
  }
}

@media only screen and (max-width: 767px){
  .large {
    grid-row: span 9;
  }
  .medium {
    grid-row: span 9;
  }
  .small {
    grid-row: span 9;
  }
  .portfolio_banner_container {
    padding: 30px 20px 80px 20px;
}
.portfolio_grid_container .cardImg {
  width: 100%;

  object-fit: cover;
}
.portfolio_container {
  padding: 20px 20px;
}
  .portfolio_grid_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-auto-rows: 65px;
    grid-auto-flow: dense;
    grid-gap: 30px;
}
.portfoliocard {
  padding: 20px 20px;
  top: -130px;
}
.banner_heading{
  font-size: 30px;
  line-height: 35px;
}
}

@media only screen and (max-width: 495px){
  .portfolio_grid_container {
    grid-template-columns: repeat(auto-fit, minmax(347px, 1fr));
    grid-auto-rows: 35px;
}
}
@media only screen and (max-width: 416px){
  .portfolio_grid_container {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: 21px;
}
.heading {
  font-size: 16px;
  line-height: 16px;
}
.sub_heading {
  font-size: 12px;
  line-height: 20px;
}
.portfoliocard {
  padding: 9px 20px;
  top: -85px;
}
.banner_heading {
  font-size: 25px;
  line-height: 26px;
  padding: 0; 
}
.medium {
  grid-row: span 7;
}
.small {
  grid-row: span 6;
}
.large {
  grid-row: span 8;
}
.portfolio_container {
  top: -5px;
}
.portfolio_banner:before {
  height: 100%; 
}
.portfolio_banner::after {
  height: 100%; 
}
.portfolio_banner_container {
  padding: 30px 20px 21px 20px;
}
}
@media only screen and (max-width: 320px){
  .banner_sub_heading {
    font-size: 12px;
    line-height: 45px;
}
.banner_heading {
  font-size: 18px;
  line-height: 18px;
  padding: 0;
}
.medium {
  grid-row: span 7;
}
.small {
  grid-row: span 6;
}
.large {
  grid-row: span 8;
}
.portfolio_container {
  top: -5px;
}
.portfolio_banner:before {
  height: 100%; 
}
.portfolio_banner::after {
  height: 100%; 
}
.portfolio_banner_container {
  padding: 30px 20px 21px 20px;
}
}