
.team_container{
    max-width:1300px;
    margin: 0 auto;
    padding: 100px 15px;
}

.team_grid_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));
    grid-auto-rows: 30px;
    grid-auto-flow: dense;
    grid-gap: 20px;
  }
  
.person {
    height: 100%;
    width: 100%;
    grid-column: auto / span 1;
    grid-row: span 8;
  }
  
  .team_grid_container  .personIMG {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .team_card{
    width: 75%;
    background-color: #fff;
    padding: 10px 20px;
    position: relative;
    top: -150px;
    z-index: 1;
    margin: 0 auto;
    text-align: center;
   
  }
  .person .team_card{
    opacity: 0;
  }
  .person:hover .team_card{
    opacity: 1;
    transition: 1s ease-out;
  }
  .person_name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #161616;
  }
  
  .team_social{
    padding: 4px 7px;
    border-radius: 50%;
  }
  .team_social:hover {
    background-color: #FA5D32;
    transition: 2s all ;
    cursor: pointer;
   
  }
  .team_social:hover .team_social_icon{
    color: #ffff;
    transition: .8s all ;
  }
 
@media only screen and (max-width: 980px){
  .service_heading {
    font-size: 24px;
    line-height: 30px;
}
}
@media only screen and (max-width: 767px){
  .person {
    height: 100%;
    max-width: 100%;
    grid-column: auto / span 1;
    grid-row: span 9;
    text-align: center;
  }
  .team_container {
    padding: 40px 20px;
}
  .team_grid_container .personIMG {
    width: 100%;
    height: 100%;
    object-fit: contain;

  }
  .service_heading {
    font-size: 30px;
    line-height: 30px;
}
}
@media only screen and (max-width: 375px){
  .team_container {
    padding: 20px 20px;
}
.service_sub_heading {
  font-size: 14px;
  line-height: 24px;
}
.service_heading {
  font-size: 24px;
  line-height: 30px;
}
.person {
  height: 100%;
  max-width: 100%;
  grid-column: auto / span 1;
  grid-row: span 9;
  text-align: center;
}
}
@media only screen and (max-width: 320px){
  .team_container {
    padding: 20px 20px;
}
.service_heading {
  font-size: 18px;
  line-height: 18px;
}
.team_grid_container .personIMG {
  width: 100%;
  height: 100%;
  object-fit: none;
}
.person {
  height: auto;
  max-width: 100%;
  grid-column: auto;
  grid-row: span 7;
  text-align: center;
}
.person_name {
  font-size: 12px;
  line-height: 10px;
}
.person_desig {
  font-size: 11px;
  line-height: 14px
}
}