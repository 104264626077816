
.pricing_card_section{
    width: 100%;
}
.pricing_card{ 
    position: relative;
    z-index: 1;
}
.pricing_card:before{
    content: '';
    background-image: url(../../Images/pricingcardBG.png);
    background-repeat: no-repeat;
    width: 100%;
   height: 100%;
    position: absolute;
    top: 0;
}
.pricing_card:after{
    content: '';
    background-color: #16161610;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.pricing_banner_container{
    max-width: 1300px;
    margin: 0 auto;
    padding: 107px 0 237px 0;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: 0; 
    margin-left: 0
}
.pricing_sub_heading::before{
    content: "";
    width: 35px;
    height: 35px;
    background: #FA5D32;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -11;
}
.pricing_sub_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #161616;
    position: relative;
    z-index: 999;
    padding-left: 16px;
    width: fit-content;
    margin: 0 auto ;
}
.pricing_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #161616;
}
.pricing_card_title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    background-color: #161616;
    padding: 15px 0;
}
.pricing_card_body{
    display: flex;
}
.dollar{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #161616;
}
.per_month{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #777777;
}
.offer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #161616;
}
.pricingcard_right{
    padding: 10px 0;
}
.pricingcard_text_cont{
    border-left: 2px solid #16161620; ;
}
.pricing_card_texts{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #777777;
    padding: 5px;
}
a {
    text-decoration: none;
  }
  .pricingcard_rightangle_icon{
    color: #161616;
    background-color: #ffff;
    width: 40px;
    height: 40px;
  }
.pricingCard_button{
    padding: 10px 15px;
    background-color: #161616;
    border: none;
    display: flex;
    justify-content: space-around;
    column-gap: 10px;
}
.pricingcard_btn_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #FFFFFF; 
}
.pricingcard {
    --bs-card-spacer-y: 2rem;
    --bs-card-spacer-x: 2rem;
    --bs-card-title-spacer-y: 2.5rem;
    --bs-card-border-width: 0px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: -0.625rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}
.pricingcard:before{
    content:"";
    background:#FA5D32;
    width:0;
    height:2px;
    position:absolute;
    bottom: 0;
    left:0;
  }
  .pricingcard:after{
    content:"";
    background:#FA5D32;
    width:0;
    height:2px;
    position:absolute;
    bottom: 0;
    right:0;
    left: 0;
  }
  .main_pricingcard:hover .pricingcard:after,
  .main_pricingcard:hover .pricingcard:before{
    width:100%;
    transition: all 0.9s ease-in-out;
  }
  
.pricingCard_button:hover{
    background-color: #FA5D32;
    transition: .8s ease-in-out;
   
}
.pricingcard:hover .pricing_card_title{
    background-color: #FA5D32;
    transition: .8s ease-in-out;
}

.g-4, .gy-4 {
    --bs-gutter-y: 1.5rem;
}
.g-4, .gx-4 {
    --bs-gutter-x: 1.5rem;
}
.pricing_banner_section_image{
    background-image: url(../../Images/pricingbanner.png);
    width: 95.6%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 1;
    margin:-170px auto 0;
    
}
.pricing_card_contain{
    padding: 30px 0;
}
.card_price_section{
    padding: 20px 20px;
}
.pricing_banner_section_image:after{
    content: '';
    background-color: #000;
    opacity: 0.6;
    height:100%;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: -1;
    
}
.pricing_banner_container_image{
    max-width: 1300px;
    margin: 0 auto; 
    padding: 152px 0;
}
.pricing_banner_header{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #FFF;
    text-align: center;
    margin-bottom: 15px;
}
.pricing_banner_para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.pricingCard_banner_button{
    padding: 10px 15px;
    background-color: #FA5D32;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    min-height: 48px;
    min-width: 170px;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: .3s;
    transition-property: color;
    transition: all .5s ease;
}
.pricingCard_banner_button::before{
    background: #FA5D32;
    border-radius: 5px;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    transition-duration: .3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    transition-timing-function: ease-out;
    z-index: -1;
}
.checkmark{
    font-size: 18px;
    margin: 0 5px;
}
.pricingCard_banner_button:hover::before{
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
}
.pricingCard_banner_button:hover{
    background-color: black;
    transition: 0.8s ease-in-out;
    box-shadow: 1px 1px 25px 10px  #FA5D3240;
}
.btn_arrow{
    width: 20%;
}
@media only screen and (min-width: 1276px) and (max-width: 1800px){
    .pricing_banner_container_image{
        padding: 80px 0;
    }
}
@media only screen and (min-width: 1061px) and (max-width: 1275px){
    .pricing_card_title {
        font-size: 22px;
        line-height: 25px;
    }
    .pricing_banner_container_image{
        padding: 40px 0;
    }
    .dollar {
        font-size: 25px;
        line-height: 25px;
    }
    .pricingCard_button {
        padding: 6px 6px;
    }
    .pricingcard_btn_text {
        font-size: 16px;
        line-height: 30px; 
    }
    .pricing_card_texts {
        font-size: 12px;
        line-height: 25px;
        padding: 5px;
    }
    .pricing_banner_container {
        padding: 51px 0 200px 0;
    }
}
@media only screen and (min-width: 956px) and (max-width: 1060px){
    .pricing_card_title {
        font-size: 20px;
        line-height: 15px;
    }
    .pricing_banner_container_image{
        padding: 40px 0;
    }
    .dollar {
        font-size: 25px;
        line-height: 25px;
    }
    .pricingCard_button {
        padding: 6px 6px;
    }
    .pricing_card_texts {
        font-size: 10px;
        line-height: 22px;
        padding: 5px;
    }
    .card_price_section {
        padding: 10px 10px;
    }
    .pricing_banner_container {
        padding: 50px 0 200px 0;
    }
}
@media only screen  and (min-width: 768px) and (max-width: 955px){
    .pricing_card_title {
        font-size: 20px;
        line-height: 15px;
    }
    .pricing_banner_container_image{
        padding: 20px 0;
    }
    .dollar {
        font-size: 22px;
        line-height: 20px;
    }
    .per_month {
        font-size: 15px;
        line-height: 25px;
    }
    .pricingCard_button {
        padding: 7px 7px;
    } 
    .pricingcard_btn_text {
        font-size: 10px;
        line-height: 28px;
    }
    .mx-3 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .pricing_card_texts {
        font-size: 10px;
        line-height: 21px;
        padding: 2px;
    }
    .card_price_section {
        padding: 10px 10px;
    }
}
@media only screen  and (min-width: 541px) and (max-width: 767px){
    .pricing_banner_container {
        padding: 40px 20px 170px 20px;
    }
    .pricing_card_body {
        display: flex;
        justify-content: center;
    }
    .pricing_banner_container_image{
        padding: 10px 0;
    }
    .pricing_card_contain {
        padding: 25px 0;
    }
    .dollar {
        font-size: 22px;
        line-height: 20px;
    }
    .per_month {
        font-size: 15px;
        line-height: 25px;
    }
    .pricingCard_button {
        padding: 7px 7px;
    } 
    .pricingcard_btn_text {
        font-size: 10px;
        line-height: 30px;
    }
    .pricingcard_rightangle_icon {
        width: 30px;
        height: 30px;
    }
    .button {
        padding: 10px 10px;
    }
    .rightangle_icon {
        width: 30px;
        height: 30px;
    }
    .pricing_heading {
        font-size: 30px;
        line-height: 40px;
    }
    .pricing_card_title {  
        font-size: 20px;
        line-height: 20px;
    }
    .pricing_banner_header {
        font-size: 30px;
        line-height: 40px;
    }
    .pricing_banner_para {
        
        font-size: 18px;
        line-height: 10px;
       margin-bottom: 10;
    }
    .pricing_card:before{
        background-image: none;
    }
}
@media only screen and (max-width: 540px){
    .pricing_banner_container {
        padding: 20px 20px 120px 20px;
    }
    .pricing_banner_container_image{
        padding: 10px 0;
    }
    .pricing_card_body {
        display: flex;
        justify-content: center;
    }
    .pricing_sub_heading {
        font-size: 15px;
        line-height: 40px;
    }
    .pricing_heading {
        font-size: 25px;
        line-height: 30px;
    }
    .pricing_card_contain{
        padding: 0px 0px;
    }
    .pricing_card_title {
        font-size: 16px;
        line-height: 0px;
    }
    .card_price_section{
        padding: 10px 10px;
    }
    .dollar {
        font-size: 22px;
        line-height: 20px;
    }
    .per_month {
        font-size: 11px;
        line-height: 21px;
    }
    .offer {
        font-size: 13px;
        line-height: 30px;
    }
    .pricingcard_btn_text {
        font-size: 11px;
        line-height: 25px;
    }
    .pricingCard_button {
        padding: 6px 7px;
    }
    .mx-3 {
        margin-right: 0.5rem !important;
        margin-left: 0.2rem !important;
    }
    .pricing_card_texts {
        font-size: 9px;
        line-height: 24px;
        padding: 1px;
    }
    .pricing_banner_header {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 0px;
    }
    .pricing_banner_para {
        padding: 13px 5px;
        font-size: 18px;
        line-height: 17px;
    }
    .pricing_banner_section_image {
        margin: -130px auto 0;
    }
    .pricingcard_rightangle_icon{
        width: 25px;
        height: 25px;
      }
}
@media only screen and (max-width: 375px){
    .pricing_banner_container {
        padding: 30px 20px 140px 20px;
    }
    .pricing_sub_heading::before {
        width: 25px;
        height: 25px
    }
    .pricing_sub_heading {
        font-size: 12px;
        line-height: 30px;
    }
    .pricing_heading {
        font-size: 22px;
        line-height: 30px;
    }
    .pricing_banner_header {
        font-size: 20px;
        line-height: 24px;
    }
    .pricing_banner_para {
        padding: 13px 5px;
        font-size: 14px;
        line-height: 10px;
        margin-bottom: 5px;
    }
    .rightangle_icon {
        width: 25px;
        height: 25px;
    }
    .button {
        padding: 2px 6px;
        min-height: 30px;
        min-width: 120px;
    }
    .btn_text {
        font-size: 12px;
        line-height: 30px;
    }
}
@media only screen and (max-width: 320px){
    .pricingcard_rightangle_icon {
        width: 20px;
        height: 20px;
    }
    .pricingcard_btn_text {
        font-size: 8px;
        line-height: 20px;
    }
    .checkmark {
        font-size: 19px;
        margin: 0 0px;
    }
    .pricing_card_texts {
        font-size: 8px;
        line-height: 17px;
        padding: 1px;
    }
    .pricing_banner_header {
        font-size: 15px;
        line-height: 12px;
    }
    .pricing_banner_para {
        font-size: 11px;
    }
}