#service .serviceContainer{
    padding: 30px 15px 15px 15px;
}
.service{
    background-color: #a19d9d12;
}
.serviceContainer{
    max-width:1300px;
    margin: 0 auto;
    padding: 100px 15px 50px 15px;

}
.service_sub_heading::before{
    content: "";
    width: 35px;
    height: 35px;
    background: #FA5D32;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -11;
}
.service_sub_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    position: relative;
    color: #161616;
    z-index: 999;
    padding-left: 16px;
    width: fit-content;
    margin: 0 auto ;
}
.service_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #161616;
}
.service_cards_container{
    display: flex;
    justify-content: space-between;

}
.service_card{
    background-color: #fff;
    padding: 15px 15px;
    width: 200px;
}
.card_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #161616;
    padding-bottom: 15px;
}
.heding_line{
    border-bottom: 2px solid  #FA5D32;
    margin: 0 auto;
    width: 100px;
}

.card {
    --bs-card-spacer-y: 3.5rem;
    --bs-card-spacer-x: 2rem;
    --bs-card-title-spacer-y: 1rem;
    --bs-card-border-width: 0px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: -0.625rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}
.rowgap {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: -18px;
}
.card:before{
    content:"";
    background:#FA5D32;
    width:0;
    height:2px;
    position:absolute;
    top:0;
    left:0;
}
.card:after{
    content:"";
    background:#FA5D32;
    width:0;
    height:2px;
    position:absolute;
    top:0;
    right:0;
    left: 0;
}
.maincard:hover .card:after,
.maincard:hover .card:before{
    width:100%;
    transition: all 0.9s ease-in-out;
}
.card:hover{
    background-color: #fde8e7e1;
    transition: .8s;
    
}
.servicecard_title:before{
    content:"";
    background:#FA5D32;
    width:0;
    height:2px;
    position:absolute;
    bottom:0;
    left:50%;
}
.servicecard_title:after{
    content:"";
    background:#FA5D32;
    width:0;
    height:2px;
    position:absolute;
    bottom:0;
    right:50%;
}
.card:hover .servicecard_title:after,
.card:hover .servicecard_title:before{
    width:20%;
    transition: all 0.8s ease-in-out;
}

.servicecard_title {
    padding-bottom: var(--bs-card-title-spacer-y);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #161616;
    position: relative;
}
.card_paragraph {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #777777;
    padding: 20px 7px 5px 7px;
}
.service_icon {
    width: 26%;
    height: 85px;
}
.serviceCircle{
    text-align: center;
    position: relative;
    z-index: 1;
    top: -25px;
}
.serviceCircle img {
    opacity: 0;
}
.maincard:hover .serviceCircle img{
    opacity: 1;
    transition: 1s ease-in;
}
.service_icon_img{
    text-align: center;
    padding: 20px 0;
}
@media only screen and (max-width: 1200px){
    .service_icon {
        width: 26%;
        height: auto;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 1080px){
    .servicecard_title {
        font-size: 20px; 
    }
   
    .card_paragraph {
        margin-top: 15px;
        font-size: 15px;
    }
    .service_icon_img{
        text-align: center;
        padding: 5px 0;
    } 
}
@media only screen and (max-width: 767px){
    .serviceContainer {
        padding: 40px 20px 20px 20px;
    }
    .row > * {
        padding-right: 0;
        padding-left: 0;
    }
}
@media only screen and (max-width: 375px){
    .service_sub_heading {
        font-size: 14px;
        line-height: 30px;
    }
    .serviceContainer {
        padding: 20px 20px;
    }
    .card {
        --bs-card-spacer-y: 1rem;
        --bs-card-spacer-x: .5rem;
    }
    .card_paragraph {
        padding: 8px 7px 5px 7px;
    }
}

@media only screen and (max-width: 320px){
    .service_heading {
        font-size: 21px;
        line-height: 22px;
    }
    .servicecard_title {
        font-size: 16px;
        line-height: 22px;
    }
    .card_paragraph {
        padding: 0 0;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .service_heading {
        font-size: 18px;
        line-height: 18px;
    }
}
