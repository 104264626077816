.about .aboutContainer {
    padding: 30px 15px 100px 15px;
}
.AboutImg{
    background-image: url(../../Images/AboutBG.png);
    width: 100%;
    
    background-position: center;
}
.aboutContainer{
    max-width:1300px;
    padding: 100px 15px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    column-gap: 47px;  
}
.about_left_container{
    width: 50%;
}
.about_sub_heading::before{
    content: "";
    width: 35px;
    height: 35px;
    background: #FA5D32;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -11;
}
.about_sub_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 60px;
    position: relative;
    color: #161616;
    z-index: 999;
    padding-left: 18px;
    margin-bottom: 10px;
}
.about_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #161616;
}
.about_para_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #161616;
}
.about_para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #777777;
}
.about_left_devision{
    display: flex;
    justify-content: flex-start;
    column-gap: 113px;
    
}
.about_card{
    display: flex;
    column-gap: 16px;
    width: 205px;
}
.about_card_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #161616;
}
.button{
    padding: 10px 15px;
    background-color: #FA5D32;
    border: none;
}
.btn_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 5px;
}
.about_right_container{
    width: 50%;
    position: relative
}
.about_img_block{
    display: flex;
    justify-content: end;
    position: relative;
    height: 90%;
}
.aboutImg{
    width: 90%;
    display: flex;
    align-items: end;
    justify-content: end;
    position: relative;
    
}
.experience{
    border: 5px solid #FA5D32;
    border-radius: 50%;
    display: flex;
    width: 182px;
    height: 182px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 60px;
    background: #ffff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.experience_year{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #FA5D32;
    margin-bottom: 0;
}
.experience_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #161616;
    margin-bottom: 0;
}
.about_img_cont{
    position: relative;
}
.btn_outer{
    margin: 20px 0;
}
.about_icon{
    width:54px;
    height: 54px;
}
@media only screen and (min-width: 768px) and (max-width: 1250px){
    .experience{
        border-radius: 50%;
        width: 150px;
        height: 150px;
        
    }
    .experience_year{
        font-size: 30px;
        line-height: 50px;
    }
    .experience_text{
        font-size: 18px;
        line-height: 30px;
    }
    .about_heading {
        font-size: 30px;
        line-height: 40px
    }
    .about_para_heading {
        font-size: 18px;
        line-height: 26px
    }
    .about_heading {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 15px; 
    }
    .about_para_heading {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 15px;
    }
    .about_para {
        font-size: 15px;
        line-height: 20px;
    }
    .about_left_devision {
        padding: 10px 10px;
    }
    .about_card_heading {
        font-size: 18px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 767px){
    .aboutContainer {
        max-width: 1300px;
        padding: 20px 20px 70px 20px;
        display: block;
    }
    .about_left_devision {
        justify-content: space-evenly;
        padding: 14px 50px;
    }
    .aboutImg {
    width: 100%;
    text-align: center;
    padding: 0 20px;
    }
    .about_right_container{
        width: 100%;
        position: relative;
    }
    .about_left_container{
        width: 100%;
    }
    .experience{
        left: 20px;
    }
    .about_icon{
        width: 40px;
        height: 40px;
    }
    .about_left_devision {
        display: flex;
        column-gap: 33px;
    }
    .about_heading {
        font-size: 30px;
        line-height: 40px;
    }
}
@media only screen and (max-width: 375px){
    .about_heading {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 9px;
    }
    .about_para_heading {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .about_para {
        font-size: 13px;
        line-height: 18px;
    }
    .about_left_devision {
        justify-content: space-evenly;
        padding: 0px 0px;
    }
    .about_card_heading {
        font-size: 12px;
        line-height: 16px;
    }
    .experience {
        border-radius: 50%;
        width: 115px;
        height: 115px;
        left: 0;
    }
    .experience_year {
        font-size: 22px;
        line-height: 35px;
    }
    .experience_text {
        font-size: 13px;
        line-height: 16px;
    }
    .about_left_devision {
        display: flex;
        justify-content: flex-start;
        column-gap: 33px;
    }
}
