.compare_BG{
    position: relative;
    background-image: url(../../Images//compareBG.png);
    background-repeat: no-repeat;
    display: block;
}

.compare_BG:after{
    content: '';
    background-color:#F5F5F5; 
    height:100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.compare_container{
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    padding: 80px 15px 90px 15px;
    column-gap: 32px;
}
.container_left{
    width: 100%;
}
.container_right{
    width: 100%;
    padding: 25px 0 0 0;
}
.comnpare_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #161616;

}
.compare_sub_heading::before{
    content: "";
    width: 35px;
    height: 35px;
    background: #FA5D32;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -11;
}
.compare_sub_heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 50px;
    color: #161616;
    position: relative;
    z-index: 999;
    padding-left: 18px;
    margin-bottom: 10px;
}
.compare_para{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #777777;
    margin-bottom: 35px;
}

/* accordian */

.accordion {
    --bs-accordion-color: #212529;
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-btn-padding-y: 0;
    --bs-accordion-btn-color: #212529;
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url(../../Images/accordian_icon_plus.svg);
    --bs-accordion-btn-icon-width: 1.5rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url(../../Images/accordian_icon_minus.svg);
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 0;
    --bs-accordion-body-padding-y: 0;
    --bs-accordion-active-color: #FA5D32;
    --bs-accordion-active-bg:none;
    max-width: 100%;
}
.accordion-item:first-of-type {
    border-top-left-radius: 0; 
    border-top-right-radius: 0; 
}
.accordion-item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border:none;
    margin-bottom: 20px;
    padding: 6px 20px;
    box-shadow: 0px -6px 20px rgb(0 0 0 / 5%), 0px 8px 20px rgb(0 0 0 / 5%);
}
.accordion-button:focus {
    box-shadow: unset;
}

.accordion-item:last-of-type {
    border-bottom-right-radius:none;
    border-bottom-left-radius: none; 
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    color: var(--bs-accordion-btn-color);
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
   padding: 10px 0;
   gap: 25px;
}
.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: none;
}
.accordion-body{
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #777777;
    border-top: 1px solid #16161610;
    padding: 20px 0 10px 0;
}

@media only screen and (max-width: 980px){
    .comnpare_heading {
        font-size: 30px;
        line-height: 40px
    }
    .compare_para {
        font-size: 15px;
        line-height: 24px;
    }
    .accordion-button {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
    .compare_BG{
        background-image: none;
    }
    .compare_container {
        padding: 50px 15px 40px 15px;
    }
    .accordion-body {
        font-size: 15px;
        line-height: 21px;
    }
    .container_right {
        width: 100%;
        padding: 0px 0 0 0;
    }
    
}
@media only screen and (max-width: 767px){
    .compare_container {
        display: block;
    }
    .container_right {
        padding: 50px 0 0 0;
    }
    .compare_BG:before {
        display: none;
    }
    .compare_container {
        padding: 50px 20px 30px 20px;
    }
}
@media only screen and (max-width: 375px){
    .compare_container {
        padding: 20px 20px;
    }
    .container_right {
        padding: 25px 0 0 0;
    }
    .accordion-item {
        margin-bottom: 10px;
    }
    .accordion {
        --bs-accordion-btn-padding-x: 0.5rem;
        --bs-accordion-btn-padding-y: 0.5rem;
        --bs-accordion-body-padding-y:0.5rem;
        --bs-accordion-body-padding-x:0.5rem
    }
    .accordion-button {
        font-size: 15px;
    }
    .accordion-body {
        font-size: 13px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 320px){
    .compare_container {
        padding: 20px 20px;
    }
}