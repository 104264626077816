.HeroImg{
    background-image: url(../../Images/BannerImg.png);
    height:100%;
    max-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 1;
}
.HeroImg:before{
    content: '';
    background-image: url(../../Images/bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height:100%;
    position: absolute;
    top: 0;
}
.HeroImg:after{
    content: '';
    background-color: #000;
    opacity: 0.7;
    height:100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.Banner{
    position: relative;
    display: flex;
    height:100%;
    align-items: center;
}
.bannerBlock{
    max-width:1300px;
    padding: 110px 15px;
    display: flex;
    justify-content: space-between;
    margin: 118px auto;
}
.headingSm::before{
    content: "";
    width: 53px;
    height: 53px;
    background: #FA5D32;
    opacity: 0.5;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -11;
}
.headingSm{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    position: relative;
    padding-left: 30px;
    z-index: 999;
    margin-bottom: 24px;
}
.btn{
    margin-top: 32px;
}
.button{
    padding: 10px 22px;
    background-color: #FA5D32;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    min-height: 48px;
    min-width: 150px;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: .3s;
    transition-property: color;
    transition: all .5s ease;
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 7px;
    
}

.button:before{
    background: #FA5D32;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    transition-duration: .3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    transition-timing-function: ease-out;
    z-index: -1;
}

button.button:hover:before {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
}

.button:hover{
    background-color: black;
    transition: 0.8s ease-in-out;
    box-shadow: 1px 1px 25px 10px  #FA5D3240;
}
.btn_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}
.rightangle_icon{
    color: #161616;
    background-color: #ffff;
    width: 40px;
    height: 40px;
}
.headingBg{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 85px;
    color: #FFFFFF;
}


/* circle */
.play{
    display: flex;
    align-items: center;
    justify-content: end;
   
}
.circleLogo::before {
    content: "";
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-image: url(../../Images/Ellipse4.png);
    background-size:contain;
    background-repeat: no-repeat;
    animation: move5 4s linear infinite;
    border: none;
    position: absolute;
    left: 78.7%;
    top: 42%;
}
.circleLogo::after{
    content: "";
    height: 103px;
    width: 103px;
    position: absolute;
    background-repeat: no-repeat;
    left:80%;
    top: 45%;
    background-image: url(../../Images/Play.svg);
}

@keyframes move5{
    0%{transform: rotateZ(1deg)}
    100%{transform: rotateZ(360deg)}
}

.Heromenu{
    max-width: 1300px;
    margin: 5px 5px;
    display: flex;
    justify-content: space-between;
}
.herocard{
    display: flex;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    column-gap: 15px;
}
.herocard p{
    margin-bottom: 0;
}
.mainherocard {
    display: flex;
    justify-content: space-around;
    max-width: 1300px;
   margin: 0 auto;
   column-gap: 30px;
   position: relative;
    margin-top: -90px;
    z-index: 1;
    padding: 0px 15px;
}
.herocard_header{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #161616;
}
.herocard_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #777777;
}

@media screen and  (max-width:1600px){
    .headingBg{ 
        font-size: 60px;
        line-height: 70px;
    }
    .headingSm{     
        font-size: 20px;
        line-height: 20px;
    }
    .circleLogo::before {
        left: 82.5%;
    }
    .circleLogo::after{       
        left:84%; 
    }
    .bannerBlock{
        padding: 30px 15px;
    }
}
@media screen and  (max-width:1450px){
    .mainherocard {
        column-gap: 20px;
    }
    .herocard {
        padding: 15px;
        column-gap: 10px;
    }
}
@media screen and (max-width:1249px) {
    .circleLogo{
        display: none;
    }
    .bannerBlock{
        padding: 20px 15px;
    }
}
@media only screen and (max-width: 1080px){
    .headingBg{ 
        font-size: 63px;
        line-height: 85px;
    }
    
    .headingSm{     
        font-size: 24px;
        line-height: 25px;
    }
    .bannerBlock {
        max-width: 1300px;
        padding: 10px 80px;
        display: block;  
    }
    .mainherocard { 
        column-gap: 15px;
        top: -10px;
    }
    h4{
        font-size: 18px;
    }
    p{
        font-size: 14px;
    }
    .herocard_header {
        font-size: 22px;
        line-height: 30px;
    }
    .herocard_text {
        font-size: 14px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 767px){
    .bannerBlock {
        padding: 15px 20px;
        margin: 20px auto;
    }
    .headingBg {
        font-size: 50px;
        line-height: 60px;
    }
    .mainherocard {
        display: none;
    }
    .HeroImg:before{
        display: none;
    }
    .headingSm::before{
        width: 40px;
        height: 40px;
    }
    .headingSm{
        padding-left: 18px;
    }
}
@media only screen and (max-width: 540px){
    .bannerBlock {
        margin: 10px auto;
    }
    .headingBg {
        font-size: 30px;
        line-height: 35px;
    }
    .headingSm {
        font-size: 16px;
        line-height: 20px;
    }
    .about_sub_heading {
        font-size: 12px;
    }
    .button {
        padding: 2px 12px;
        background-color: #FA5D32;
        border: none;
    }
    .btn_text {
        font-size: 14px;
        line-height: 30px;
    }
}