.feedback_banner {
  position: relative;
  z-index: 1;
}

#feedback_banner .feedback_banner {
  padding: 100px 0px 50px 0px;
}
.feedback_banner:before {
  content: "";
  background-image: url(../../Images/feedback_BG.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
}
.feedback_banner:after {
  content: "";
  background-color: black;
  height: 500px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.fedback_banner_container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 50px 15px 0px 15px;
}

.client_banner_subheading::before {
  content: "";
  width: 35px;
  height: 35px;
  background: #fa5d32;
  opacity: 0.5;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -11;
}
.client_banner_subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  position: relative;
  color: #ffffff;
  z-index: 999;
  padding-left: 18px;
  margin-bottom: 10px;
}
.feedback_banner_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
}

/* caursal */
.client_carousel_container {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  top: -30px;
}
.client_carousel {
  position: relative;
  overflow: hidden;
}

.client_container {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}
.client_carosael_card {
  color: white;
  padding: 50px 40px;
  background-color: #ffffff;
  position: relative;
}

.client_carosael_card:before {
  content: "";
  background: #fa5d32;
  width: 0;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.client_carosael_card:after {
  content: "";
  background: #fa5d32;
  width: 0;
  height: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.client_carosael_card_main:hover .client_carosael_card:after,
.client_carosael_card_main:hover .client_carosael_card:before {
  width: 100%;
  transition: all 0.9s ease-in-out;
}
.client_carosael_card:hover {
  box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.05),
    0px 8px 20px rgba(0, 0, 0, 0.05);
  transition: 0.9s;
}
.client_carousael_body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  margin: 25px 0;
}
.feedback_header {
  display: flex;
  justify-content: space-between;
}
.info_feedback {
  display: flex;
  column-gap: 15px;
}
.ratingLogo {
  width: 35%;
  height: 35%;
}
.quote {
  width: 12%;
}
.feedbackArrow {
  border: 0;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  line-height: 40px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fa5d3220;
}
.feedbackArrow:hover {
  background-color: #fa5d32;
  transition: 0.3s ease-in-out;
  color: #ffffff;
}
.personimg {
  width: 25%;
  height: 25%;
}
.client_name{
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #161616;
}
.client_desig{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777777;
}

@media only screen and (min-width: 981px) and (max-width: 1080px) {
  .fedback_banner_container {
    padding: 10px 15px 0px 15px;
  }
  .feedback_banner_heading {
    font-size: 24px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .client_carosael_card {
    padding: 22px 22px;
  }
  .client_carousael_body {
    font-size: 15px;
    line-height: 25px;
    margin: 10px 0;
  }
  .feedback_banner:after {
    height: 400px;
  }
  .feedback_banner::before {
    height: 400px;
  }
  .fedback_banner_container {
    padding: 50px 20px 45px 20px;
  }
  .arrowGroup {
    display: none;
  }
}
@media only screen and (max-width: 570px) {
  .client_carousael_body {
    font-size: 12px;
    line-height: 21px;
    margin: 10px 0;
  }
  .client_carosael_card {
    padding: 11px 11px;
  }
  .client_name{
    font-size: 15px;
    line-height: 15px;
  }
  .client_desig {
    font-size: 10px;
    line-height: 10px;
  }
  .fedback_banner_container {
    padding: 11px 20px 45px 20px;
  }
  .feedback_banner_heading {
    font-size: 22px;
    line-height: 22px;
  }
  .client_banner_subheading {
    font-size: 12px;
    line-height: 40px;
  }
  .feedback_banner::before {
    height: 270px;
  }
  .feedback_banner:after {
    height: 270px;
  }
.client_desig {
  font-size: 14px;
  line-height: 20px;
}
}

@media only screen and (max-width: 375px){
  .client_name{
    font-size: 12px;
    line-height: 15px;
  }
  .client_desig {
    font-size: 10px;
    line-height: 10px;
  }
  .client_carousael_body {
    font-size: 10px;
    line-height: 15px;
    margin: 5px 0;
}
.client_name{
  font-size: 12px;
  line-height: 15px;
}
.client_desig {
  font-size: 12px;
  line-height: 15px;
}
}
@media only screen and (max-width: 320px){
  .feedback_banner_heading {
    font-size: 18px;
    line-height: 18px;
}
.client_name{
  font-size: 12px;
  line-height: 15px;
}
.client_desig {
  font-size: 10px;
  line-height: 10px;
}
}