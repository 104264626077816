.footer {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #232323;
}

.footer::after {
  content: "";
  background-image: url(../../Images/footerBG.png);
  background-repeat: no-repeat;
  height: 100%;
  position: absolute;
  background-position: center;
  top: 0;
  width: -webkit-fill-available;
  z-index: -1;
}
.footer_container {
  max-width: 1300px;
  border-bottom: 2px solid #ffffff10;
  margin: 0 auto;
  z-index: 1;
  padding: 105px 15px 0 15px;
}
.navmanu_footer {
  display: flex;
  list-style: none;
  gap: 47px;
  margin-bottom: 0;
  align-items: center;
}
.navmanu_footer li a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
}

hr {
  margin: 1.1rem 0 0 0;
  color: inherit;
  border: 0;
  border-top: 2px solid #ffffff10;
}
.footer_nav {
  display: flex;
  justify-content: space-between;
}
.footer_para_section {
  display: flex;
  justify-content: space-between;
  column-gap: 200px;
  padding: 51px 0 20px 0;
}
.footer_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  max-width: 700px;
  margin-bottom: 0;
}
.footer_social {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #ffff;
}
.footer_social:hover {
  color: #fa5d32;
}
.footer_icons {
  display: flex;
  column-gap: 17px;
  align-items: center;
}
.footer_buttom_text {
  display: flex;
  justify-content: space-between;
}
.term_condition {
  display: flex;
  column-gap: 40px;
  justify-content: space-between;
}
.trootheme {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .footer .footer_nav {
    display: unset;
  }
  .footer .footer_para_section {
    display: unset;
  }
  .footer .footer_buttom_text {
    display: unset;
  }
  .navmanu_footer {
    gap: 30px;
  }
  .footer_icons {
    display: flex;
    column-gap: 17px;
    align-items: center;
    justify-content: end;
}
}
@media only screen and (max-width: 1080px) {
  .footer::after {
    display: none;
  }
  .footer_container {
    padding-top: 0px;
  }
  .footer_para {
    font-size: 11px;
    line-height: 15px;
  }
  .navmanu_footer li a {
    font-size: 12px;
    line-height: 20px;
  }
  .footer {
    padding: 5px 5px;
  }
  hr {
    margin: 0.2rem 0;
  }
  .footer_social {
    width: 17px;
    height: 17px;
}
}
