.header{
    display: flex;
    justify-content:space-between;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    height: 80px;   
    z-index: 9999;
    background-color:rgb(24, 10, 10);
    transition: 0.5s;
}
.logo{
    margin-left: 2%;
}

.num{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #FFFFFF;
}
.callImg{
    margin-right: 7px;
}
.callText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #FFFFFF;
}
ul{
    list-style: none;
    gap: 30px;
    margin-bottom: 0;
}

ul.nav-manu {
    display: flex;
}
.nav-manu li a{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF !important;
    text-decoration: none;
}
.nav-manu li a.active{
    color: #FA5D32 !important;
}
.humburgerLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8%;
    height: 100%;
    background-color: #FA5D32;
}
.navbar_number{
    display: flex;
}
.hamburger{
    display: none;
}
.drawer_container{
    padding: 25px 35px;
}
.drawer_manu ul{
    padding-left: 0;
}
.drawer_btn{
    padding: 10px 15px;
    background-color: #f1e7e7;
    border: none;
}
.drawer_btn_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #080808;
    margin-right: 5px;
}
.drawer_btn_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.hamburger_btn_manu{
    width: 25%;
    background-color: rgba(0, 0, 0);
    position: fixed;
    top: 80px;
    z-index: 1;
    height: 100%;
    overflow: hidden;
    right: 0; 
}
.drawer_manu{
   display: none;
}
.hamburger_btn_manu {
    transform: translateX(100%);
}
 .drawer_open{
    transform: translateX(0);
    transition: 0.7s;
}
.drawer_close{
    transition: 0.7s;
}
.hamburger_btn_manu li{
    padding: 15px 0;
}
.hamburger_btn_manu li a{
    font-size: 20px;
    color: white;
    text-decoration: none;
}
@media screen and (min-width:1305px) and (max-width:1400px){
    .hamburger_btn_manu {
        width: 30%;
    }
    .navbar_number{
        display: none;
    }
   }
@media screen and (min-width:1250px) and (max-width:1304px){
    ul{
        gap: 20px;
    }
    .nav-manu li a{
        font-size: 14px;
    }
    
    .hamburger_btn_manu {
        width: 35%;
    }
    .navbar_number{
        display: none;
    }
}

@media screen and (max-width:1249px) {
    .navbar .nav-manu{
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
        display: none;
    }
    .nav-manu{
        left: 0;
    }
    .nav-manu li{
        padding: 12px 0;
    }

    .hamburger{
        display:initial;
        height: 0px;
        width: 0px;
    }
    .hamburger_btn_manu {
        width: 35%;
    }
    .drawer_manu{
        display: block;
    }
    .drawer_manu .nav-manu{
        display: block;
    }
    .navbar_number{
        display: none;
    }
    
}
@media screen and (max-width:1080px){
    .hamburger_btn_manu {
        width: 50%;
    }
}
@media only screen and (max-width: 767px){
    .navbar_number{
        display: none;
    }
    .hamburger_btn_manu li a {
        font-size: 20px;
    }
    .hamburger_btn_manu {
     width: 100%;
     }
     .humburgerLogo{
        width: 15%;
    }
    .drawer_manu{
        display: block;
    }
}
@media screen and (max-width:370px) {
    .logoImg {
        width: 80%;
    }
    .header {
        height: 50px
    }
    .hamburger_btn_manu {
        top: 50px;
    }
}
